export const convertObjectToArrayByKey = (
    object: any,
    itemKey: string,
): any[] => {
    if (!object) {
        return [];
    }
    return Object.keys(object).map((key) => {
        const item = object[key];
        return item[itemKey];
    });
};

export const sleep = async (msTime: number) =>
    new Promise((resolve) => setTimeout(resolve, msTime));

import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { isNil } from 'lodash';
import {
    validateAge,
    validateAgeAtDate,
    validateNotEarlierThanPickOffDateWithExcludedValue,
    validateNotInPastWithExcludedValue,
} from 'src/utils/ValidationHelper';
import {
    carouselDotsSelector,
    sciBrandNameSelector,
} from 'src/components/shared/SciPage/SciPage.selector';
import {
    AUSTRALIA,
    defaultMinAge,
    StateCountries,
    UNITEDSTATES,
} from 'src/constants';
import { AllBrands, UsBrandNames } from 'src/enums';
import { useSci } from 'src/hooks/useSci';
import { useBookingSummaryKey } from 'src/hooks/useBookingSummary';
import { considerDateAsUtc } from 'src/utils/DateHelper';
import { QueryClientAccessor } from 'src/redux/QueryClientReduxCompatible';

import { Driver } from '../addDriver/types';
import {
    countrySelector,
    customerInfoSelector,
    primaryProductSelector,
    countriesObjectSelector,
} from '../updateCustomerInfo/updateCustomerInfo.selector';
import {
    defaultDriver,
    retrieveDriversAction,
} from '../addDriver/ducks/retrieveDriver.duck';
import { retrieveStatesAction } from '../addDriver/ducks/retrieveStates.duck';

import { retrieveCustomerInfoAction } from '../updateCustomerInfo/retrieveCustomerInfo.duck';
import { retrieveCountriesAction } from '../updateCustomerInfo/retrieveCountries.duck';
import {
    retrieveDriversIsLoadingSelector,
    statesSelector,
} from '../addDriver/addDriver.selector';
import { retrieveOrganizationSettingsAction } from '../updateCustomerInfo/retrieveOrganizationSettings.duck';

import {
    updateAdditionalDriversErrorSelector,
    updateAdditionalDriversIsLoadingSelector,
    additionalDriverSelectors,
} from './addAdditionalDrivers.selector';
import { deleteAdditionalDriverAction } from './ducks/deleteAdditionalDriver.duck';
import { updateAdditionalDriversAction } from './ducks/updateAdditionalDrivers.duck';
import msg from './addAdditionalDriverMessages';

const defineFormSchema = (
    intl: any,
    primaryProduct: any,
    brandName: AllBrands | undefined,
    rental: any,
) => {
    const isUSBrand =
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte;

    return {
        document: yup.object().shape({
            number: yup
                .string()
                .required(intl.formatMessage(msg.fields.enterLicenseNumber)),
            validEnd: yup
                .date()
                .nullable()
                .test(
                    'validEnd',
                    intl.formatMessage(msg.fields.expiredLicense),
                    (value) =>
                        !value
                            ? true
                            : validateNotInPastWithExcludedValue(
                                  value?.toDateString(),
                              ),
                )
                .test(
                    'validEnd',
                    isUSBrand
                        ? intl.formatMessage(
                              msg.fields.licenseEarlierThanDropOff,
                          )
                        : intl.formatMessage(
                              msg.fields.licenceEarlierThanDropOff,
                          ),
                    (value) =>
                        !value
                            ? true
                            : validateNotEarlierThanPickOffDateWithExcludedValue(
                                  value?.toDateString(),
                                  rental?.end?.date ?? '',
                              ),
                ),
            validStart: yup
                .date()
                .nullable()
                .max(
                    new Date(),
                    intl.formatMessage(msg.fields.invalidIssueDate),
                ),
            dateOfBirth: yup.date().when('dateOfBirthToggle', {
                is: '',
                then: (schema) =>
                    schema
                        .required(
                            intl.formatMessage(msg.fields.enterDateOfBirth),
                        )
                        .test(
                            'dateOfBirth',
                            intl.formatMessage(msg.fields.invalidAge, {
                                minAge: `${
                                    primaryProduct.minAge ?? defaultMinAge
                                }`,
                            }),
                            (value: Date) =>
                                validateAge(
                                    value,
                                    primaryProduct.minAge ?? defaultMinAge,
                                ),
                        ),
                otherwise: (schema) => schema,
            }),
            dateOfTest: yup
                .date()
                .test(
                    'dateOfTest',
                    intl.formatMessage(msg.fields.invalidDateOfTest),
                    (value?: Date) =>
                        !rental?.start?.date ||
                        !value ||
                        validateAgeAtDate(
                            value,
                            new Date(rental?.start?.date),
                            2,
                        ),
                ),
        }),
        email: yup
            .string()
            .email(intl.formatMessage(msg.fields.validEmail))
            .required(intl.formatMessage(msg.fields.enterEmail)),
        firstName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterFirstName)),
        lastName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterLastName)),
    };
};

const mapDataDriver = (data: { driversArray: Driver[] }, drivers: Driver[]) => {
    const allDrivers = [] as Driver[];
    const dataDrivers = data?.driversArray ?? [];

    for (const dataDriver of dataDrivers) {
        const prevDriverData = drivers.find(
            (driver) => driver.id?.profileId === dataDriver.id?.profileId,
        );
        if (dataDriver.document) {
            if (!dataDriver.document.documentType) {
                dataDriver.document.documentType =
                    defaultDriver.document.documentType || {};
            }

            if (!dataDriver.document.documentType.issuer) {
                dataDriver.document.documentType.issuer =
                    prevDriverData?.document?.documentType?.issuer || '';
            }

            if (!dataDriver.document.documentType.issuerName) {
                dataDriver.document.documentType.issuerName =
                    prevDriverData?.document?.documentType?.issuerName || '';
            }

            if (dataDriver.document.validEnd) {
                dataDriver.document.validEnd =
                    considerDateAsUtc(dataDriver.document.validEnd) ??
                    undefined;
            }

            if (dataDriver.document.validStart) {
                dataDriver.document.validStart =
                    considerDateAsUtc(dataDriver.document.validStart) ??
                    undefined;
            }

            if (dataDriver.document.dateOfBirth) {
                dataDriver.document.dateOfBirth =
                    considerDateAsUtc(dataDriver.document.dateOfBirth) ??
                    undefined;
            }

            allDrivers.push(dataDriver);
        }
    }

    return allDrivers;
};

export const useAddAdditionalDriversForm = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const brandName = useSelector(sciBrandNameSelector);
    const primaryProduct = useSelector(primaryProductSelector);
    const selfCheckIn = useSci();

    const rental = selfCheckIn?.data?.rental;
    const numberOfAdults = rental?.numberOfAdults ?? 0;

    const formSchema = defineFormSchema(
        intl,
        primaryProduct,
        brandName,
        rental,
    );

    const fieldsSchema = yup.object().shape({
        driversArray: yup.array().of(yup.object().shape(formSchema)),
    });
    const country = useSelector(countrySelector);
    const updateAdditionalDriversIsLoading = useSelector(
        updateAdditionalDriversIsLoadingSelector,
    );
    const drivers = useSelector(additionalDriverSelectors);
    const driversAreLoading = useSelector(retrieveDriversIsLoadingSelector);
    const statesWithCountries = useSelector(statesSelector);
    const [dialogVisible, setDialogVisible] = useState(false);
    const carouselDots = useSelector(carouselDotsSelector);
    const [isAddingDisabled, setIsAddingDisabled] = useState(false);
    const customerInfo = useSelector(customerInfoSelector);
    const [dialogIndex, setDialogIndex] = useState(0);
    const countriesObject = useSelector(countriesObjectSelector);
    const updateAdditionalDriverErrors = useSelector(
        updateAdditionalDriversErrorSelector,
    );

    const {
        handleSubmit,
        control,
        formState,
        trigger,
        setValue,
        reset,
        getValues,
        register,
    } = useForm({
            resolver: yupResolver(fieldsSchema),
            defaultValues: {
                driversArray: drivers,
            },
            shouldUnregister: true,
        });

    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'driversArray',
        shouldUnregister: true,
        keyName: 'driversArrayId',
    });

    useEffect(() => {
        if (!customerInfo?.id?.profileId) {
            dispatch(retrieveCustomerInfoAction());
        }
        if (countriesObject.length <= 0 || !countriesObject[0].id?.pointId) {
            dispatch(retrieveCountriesAction());
        }
        if (isNil(statesWithCountries)) {
            dispatch(retrieveStatesAction());
        }
    }, [dispatch, customerInfo, countriesObject, statesWithCountries]);

    useEffect(() => {
        if (!driversAreLoading) {
            reset({ driversArray: drivers });
        }
    }, [reset, driversAreLoading, drivers]);

    const onSubmit: SubmitHandler<{ driversArray: Driver[] }> = (data) => {
        dispatch(
            updateAdditionalDriversAction({
                rentalId: rental?.id?.rentalId,
                drivers: mapDataDriver(data, drivers),
                reset,
            }),
        );
    };

    const hideDialogAndRemoveComponent = useCallback(
        (index: number, profileId?: string) => {
            if (profileId) {
                dispatch(
                    deleteAdditionalDriverAction({
                        rentalId: rental?.id?.rentalId,
                        profileId: profileId,
                        onSuccessCallback: () => {
                            dispatch(
                                retrieveDriversAction({
                                    rentalId: rental?.id?.rentalId,
                                }),
                            );
                            setDialogVisible(false);
                            QueryClientAccessor.getInstance()?.refetchQueries({
                                queryKey: [useBookingSummaryKey],
                            });
                        },
                    }),
                );
            } else {
                remove(index);
                setDialogVisible(false);
            }
        },
        [rental?.id?.rentalId, remove, dispatch],
    );

    const addDriver = (index: number) => {
        const newDriver: Driver = {
            ...defaultDriver,
            document: {
                number: '',
                attributes: {},
                documentType: {
                    issuer: '',
                    issuerName: '',
                    name: '',
                },
            },
            hirer: false,
        };
        append(newDriver);
        setValue(
            `driversArray.${index}.document.documentType.issuer`,
            country?.code,
        );
        setValue(
            `driversArray.${index}.document.documentType.issuerName`,
            country?.name,
        );
        country?.name === StateCountries[UNITEDSTATES] ||
        StateCountries[AUSTRALIA]
            ? setValue(
                  `driversArray.${index}.document.documentType.state`,
                  statesWithCountries?.filter((item: any) =>
                      item.parents?.some(
                          (parent: any) => parent.code === country.code,
                      ),
                  )[0]?.name,
              )
            : setValue(`driversArray.${index}.document.documentType.state`, '');

        setValue(`driversArray.${index}.document.number`, '');
    };

    const setIndexAndDialogVisible = (index: number) => {
        setDialogVisible(true);
        setDialogIndex(index);
    };

    const handleCountryChange = (selected: string, index: number) => {
        const selectedCountry: any = Object.values(countriesObject).find(
            (item: any) => item.name === selected,
        );
        setValue(
            `driversArray.${index}.document.documentType.issuer`,
            selectedCountry.code,
        );
        setValue(
            `driversArray.${index}.document.documentType.state`,
            selectedCountry.name === StateCountries[UNITEDSTATES] ||
                selectedCountry.name === StateCountries[AUSTRALIA]
                ? statesWithCountries?.filter((item: any) =>
                      item.parents?.some(
                          (parent: any) => parent.code === selectedCountry.code,
                      ),
                  )[0]?.name
                : '',
        );
        const driver = Object.values(Object.values(getValues())[0])[index];
        update(index, driver);
    };

    const handleStateChange = (selected: string, index: number) => {
        const state: any = Object.values(statesWithCountries ?? []).find(
            (item: any) => item.name === selected,
        );
        setValue(
            `driversArray.${index}.document.documentType.state`,
            state?.name,
        );
    };

    useEffect(() => {
        if (!driversAreLoading) {
            if (!customerInfo.isDriver) {
                setIsAddingDisabled(numberOfAdults - 2 === fields.length);
            } else {
                setIsAddingDisabled(numberOfAdults - 1 === fields.length);
            }
            fields.forEach((driver, index) => {
                if ((driver && !driver.document) || !driver) {
                    setValue(
                        `driversArray.${index}.document.documentType.issuerName`,
                        country.name,
                    );
                    setValue(
                        `driversArray.${index}.document.documentType.issuer`,
                        country.code,
                    );
                    country.name === StateCountries[UNITEDSTATES] ||
                    country.name === StateCountries[AUSTRALIA]
                        ? setValue(
                              `driversArray.${index}.document.documentType.state`,
                              statesWithCountries?.filter((item: any) =>
                                  item.parents?.some(
                                      (parent: any) =>
                                          parent.code === country.code,
                                  ),
                              )[0]?.name,
                          )
                        : setValue(
                              `driversArray.${index}.document.documentType.state`,
                              '',
                          );
                }
            });
        }
    }, [
        dispatch,
        setValue,
        fields,
        fields.length,
        customerInfo,
        carouselDots?.items,
        country?.code,
        country?.name,
        numberOfAdults,
        rental?.id?.rentalId,
        statesWithCountries,
        driversAreLoading,
    ]);

    useEffect(() => {
        if (rental?.id?.rentalId) {
            dispatch(
                retrieveDriversAction({
                    rentalId: rental?.id?.rentalId,
                }),
            );
        }
        if (selfCheckIn?.data?.id?.organizationId) {
            dispatch(
                retrieveOrganizationSettingsAction({
                    organizationId: selfCheckIn?.data?.id?.organizationId,
                }),
            );
        }
    }, [dispatch, rental?.id?.rentalId, selfCheckIn?.data?.id?.organizationId]);

    return {
        onSubmit: handleSubmit(onSubmit),
        dialogVisible,
        dialogIndex,
        driversAreLoading,
        hideDialogAndRemoveComponent,
        setIndexAndDialogVisible,
        setDialogVisible,
        control,
        formState,
        trigger,
        fields,
        isAddingDisabled,
        addDriver,
        updateAdditionalDriverErrors,
        updateAdditionalDriversIsLoading,
        handleCountryChange,
        handleStateChange,
        statesWithCountries,
        register,
    };
};

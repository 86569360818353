import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as R from 'ramda';

import { B2C, Select } from 'cosmos-components';

import 'react-datepicker/dist/react-datepicker.css';

import { Input } from 'src/components/form';
import { UsBrandNames, UkBrandNames } from 'src/enums';
import { DateTimePicker } from 'src/components/shared/DateTimePicker/DateTimePicker';
import { sciBrandNameSelector, sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { ValidationError } from 'src/components';
import { Column } from 'src/components/shared/Column';
import { prepareDate } from 'src/utils/DateHelper';
import { getLangCode } from 'src/utils/string';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';
import { convertObjectToArrayByKey } from 'src/utils';
import { AUSTRALIA, CANADA, StateCountries, UNITEDSTATES } from 'src/constants';

import { countriesSelector } from '../updateCustomerInfo/updateCustomerInfo.selector';
import { defaultState } from '../addDriver/ducks/retrieveStates.duck';
import { States } from '../addDriver/types';
import globalMsg from '../../messages';

import msg from './addAdditionalDriverMessages';
import * as S from './addAdditionalDrivers.form.style';
import { useAddAdditionalDriversForm } from './addAdditionalDrivers.hooks';
import { deleteAdditionalDriversIsLoadingSelector } from './addAdditionalDrivers.selector';

const MODULE_PREFIX = 'add-additionalDriver';
export const formAddAdditionalDrivers = `${MODULE_PREFIX}-form`;
export const inputEmail = `${MODULE_PREFIX}-input-email`;
export const inputLastName = `${MODULE_PREFIX}-input-lastName`;
export const inputFirstName = `${MODULE_PREFIX}-input-firstName`;
export const dateExpiryDate = `${MODULE_PREFIX}-date-expiryDate`;
export const dateOfTest = `${MODULE_PREFIX}-dateOfTest`;
export const dateExpiryDateErrorMessage = `${MODULE_PREFIX}-date-expiryDate-error-message`;
export const dateOfTestErrorMessage = `${MODULE_PREFIX}-dateOfTest-error-message`;
export const dateIssueDate = `${MODULE_PREFIX}-date-issueDate`;
export const dateIssueDateErrorMessage = `${MODULE_PREFIX}-date-issueDate-error-message`;
export const inputLicenseNumber = `${MODULE_PREFIX}-input-licenseNumber`;
export const selectState = `${MODULE_PREFIX}-select-state`;
export const selectStateErrorMessage = `${MODULE_PREFIX}-select-state-error-message`;
export const selectIssuingCountry = `${MODULE_PREFIX}-select-issuingCountry`;
export const selectIssuingCountryErrorMessage = `${MODULE_PREFIX}-select-issuingCountry-error-message`;
export const dateDateOfBirth = `${MODULE_PREFIX}-date-dateOfBirth`;
export const dateDateOfBirthErrorMessage = `${MODULE_PREFIX}-date-dateOfBirth-error-message`;
export const addAdditionalDriver = `${MODULE_PREFIX}-add-driver-button`;
export const deleteAdditionalDriver = `${MODULE_PREFIX}-delete-driver-button`;
export const submitButton = `${MODULE_PREFIX}-submit-button`;
export const confirmMsg = `${MODULE_PREFIX}-confirm-delete-Msg`;
export const confirmDelete = `${MODULE_PREFIX}-delete-additional-driver`;
export const cancelDelete = `${MODULE_PREFIX}-cancel-delete-additional-driver`;

const GetStatesArray = (
    statesWithCountries: States,
    country: string,
    countryCode: string,
) => {
    const newStatesList = Object.values(statesWithCountries ?? []).filter(
        (item: any) =>
            item.parents?.some((parent: any) => parent.code === countryCode),
    );

    if (country === StateCountries[CANADA]) {
        newStatesList.unshift(defaultState[0]);
    }

    return convertObjectToArrayByKey(newStatesList, 'name');
};

const GenerateErrorMessage = (intl: any, errMsg: any) => {
    if (typeof errMsg === 'string') {
        if (errMsg?.includes('The email address does not match the Customer details')) {
            return intl.formatMessage(msg.error.emailNotMatch);
        } else if (errMsg?.includes('Customer email already exists')) {
            return intl.formatMessage(msg.error.emailExists);
        } else if (errMsg?.includes("Couldn't update customer address")) {
            return intl.formatMessage(msg.error.updateAddressFailed);
        } else if (errMsg?.includes("Couldn't update rental")) {
            return intl.formatMessage(msg.error.updateRentalFailed);
        } else if (errMsg?.includes("Couldn't update rental profile")) {
            return intl.formatMessage(msg.error.updateProfileFailed);
        }
    }
    return errMsg;
};

const AdditionalDriver = ({
    item,
    index,
    dialogVisible,
    dialogIndex,
    statesWithCountries,
    errors,
    control,
    formState,
    trigger,
    setIndexAndDialogVisible,
    hideDialogAndRemoveComponent,
    setDialogVisible,
    handleCountryChange,
    handleStateChange,
}: {
    item: any;
    index: number;
    dialogVisible: boolean;
    dialogIndex: number;
    statesWithCountries: States;
    errors: any[];
    control: any;
    formState: any;
    trigger: any;
    setIndexAndDialogVisible: any;
    hideDialogAndRemoveComponent: any;
    setDialogVisible: any;
    handleCountryChange: any;
    handleStateChange: any;
}) => {
    const intl = useIntl();
    const headerTitle = intl.formatMessage(
        msg.formHeaderItems.additionalDriverFormHeader,
    );
    const feeMayApply = intl.formatMessage(msg.formHeaderItems.feeMayApply);

    const emailErrorMessage = errors.find((error) => error.index === index)?.validationErrors
        ? errors.find((error) => error?.index === index)?.validationErrors['email']
        : null;

    const brandName = useSelector(sciBrandNameSelector);
    const isUSBrand =
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte;
    const isUKBrand = brandName === UkBrandNames.europe;

    const countries = useSelector(countriesSelector);
    const statesArray = convertObjectToArrayByKey(statesWithCountries, 'name');

    const isDeleteLoading = useSelector(
        deleteAdditionalDriversIsLoadingSelector,
    );
    const lang  = getLangCode(useSelector(sciLanguageSelector));
    const [_lang, setLang] = useState(lang);
    const isErr = !!Object.keys(formState?.errors).length;

    useEffect(() => {
        if (isErr && lang !== _lang) {
            trigger();
            setLang(lang);
        }
    }, [_lang, lang, trigger, isErr]);

    return (
        <AdditionalDriverForm
            key={`addtionalDriver_${index}_${item?.id?.profileId}`}
        >
            <S.AdditionalDriversFormHeader>
                {headerTitle + ` (${index + 1})`}{' '}
                <S.SpanFeeMayApply>({feeMayApply})</S.SpanFeeMayApply>
                <Input
                    key={`dateOfBirthToggle_${item.driversArrayId}`}
                    name={`driversArray[${index}].document.dateOfBirthToggle`}
                    control={control}
                    defaultValue={
                        !!item?.document?.dateOfBirth &&
                        item.id?.profileId !== undefined
                            ? 'disabled'
                            : ''
                    }
                    hidden={true}
                />
                <S.StyledIconButton
                    testId={deleteAdditionalDriver}
                    cursor="pointer"
                    name="trash"
                    size="medium"
                    isDisabled={!item.addedInSci}
                    onClick={(event) => {
                        setIndexAndDialogVisible(index);
                        event.preventDefault();
                    }}
                    color={
                        dialogVisible && index === dialogIndex
                            ? 'secondaryOrange'
                            : undefined
                    }
                />
            </S.AdditionalDriversFormHeader>
            {dialogVisible && index === dialogIndex && (
                <S.StyledConfirmMessage>
                    <S.ConfirmMessage data-test-id={confirmMsg}>
                        {intl.formatMessage(msg.formHeaderItems.confirmMsg)}
                    </S.ConfirmMessage>
                    <S.StyledYesPrimaryButton
                        testId={confirmDelete}
                        disabled={isDeleteLoading}
                        onClick={(event: Event) => {
                            hideDialogAndRemoveComponent(
                                index,
                                item?.id?.profileId,
                            );
                            event.preventDefault();
                        }}
                    >
                        {intl.formatMessage(msg.buttons.yesLabel)}
                    </S.StyledYesPrimaryButton>
                    <S.StyledNoSecondaryButton
                        testId={cancelDelete}
                        disabled={isDeleteLoading}
                        onClick={() => setDialogVisible(false)}
                    >
                        {intl.formatMessage(msg.buttons.noLabel)}
                    </S.StyledNoSecondaryButton>
                </S.StyledConfirmMessage>
            )}
            <Input
                testId={`${inputEmail}-${index}`}
                key={`email_${item.driversArrayId}`}
                name={`driversArray.${index}.email`}
                control={control}
                defaultValue={item?.email}
                label={intl.formatMessage(msg.fields.email)}
                placeholder={intl.formatMessage(msg.placeHolders.email)}
                disabled={!!item?.email && item.id?.profileId !== undefined}
                errorMessage={GenerateErrorMessage(intl, emailErrorMessage)}
            />
            <S.Row>
                <Input
                    testId={`${inputFirstName}-${index}`}
                    key={`firstName_${item.driversArrayId}`}
                    name={`driversArray.${index}.firstName`}
                    control={control}
                    defaultValue={item?.firstName}
                    label={intl.formatMessage(msg.fields.firstName)}
                    placeholder={intl.formatMessage(msg.placeHolders.firstName)}
                    disabled={!!item?.email && item.id?.profileId !== undefined}
                />
                <Input
                    testId={`${inputLastName}-${index}`}
                    key={`lastName_${item.id}`}
                    name={`driversArray.${index}.lastName`}
                    control={control}
                    defaultValue={item?.firstName}
                    label={intl.formatMessage(msg.fields.lastName)}
                    placeholder={intl.formatMessage(msg.placeHolders.lastName)}
                    disabled={!!item?.email && item.id?.profileId !== undefined}
                />
            </S.Row>
            <S.Row>
                <Controller
                    key={`dateOfBirth_${item.driversArrayId}`}
                    name={`driversArray.${index}.document.dateOfBirth`}
                    control={control}
                    defaultValue={item?.document?.dateOfBirth}
                    render={({ field, fieldState: { error, invalid } }) => (
                        <S.AdjacentPairsWrapper>
                            <S.StyledFormField
                                className={`dateOfBirth_${lang}`}
                                key={`dateOfBirth_${item.driversArrayId}`}
                                isRequired
                                label={intl.formatMessage(
                                    msg.fields.dateOfBirth,
                                )}
                                name={`driversArray.${index}.document.dateOfBirth`}
                            >
                                <DateTimePicker
                                    testId={`${dateDateOfBirth}-${index}`}
                                    key={`dateOfBirth_${item.driversArrayId}`}
                                    name={`driversArray.${index}.document.dateOfBirth`}
                                    selected={prepareDate(field.value)}
                                    onChange={field.onChange}
                                    placeholderText={intl.formatMessage(
                                        msg.placeHolders.dateOfBirth,
                                    )}
                                    disabled={
                                        !!item?.document?.dateOfBirth &&
                                        item.id?.profileId !== undefined
                                    }
                                    isError={invalid}
                                ></DateTimePicker>
                            </S.StyledFormField>

                            <ValidationError
                                data-test-id={`${dateDateOfBirthErrorMessage}-${index}`}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.AdjacentPairsWrapper>
                    )}
                />
                <Column size={6}>
                    <Input
                        className={`licenseNumber_${lang}`}
                        testId={`${inputLicenseNumber}-${index}`}
                        key={`number_${item.driversArrayId}`}
                        name={`driversArray[${index}].document.number`}
                        control={control}
                        defaultValue={item?.document?.number}
                        label={
                            isUSBrand
                                ? intl.formatMessage(msg.fields.licenseNumber)
                                : intl.formatMessage(msg.fields.LicenceNumber)
                        }
                        placeholder={
                            isUSBrand
                                ? intl.formatMessage(
                                      msg.placeHolders.licenseNumber,
                                  )
                                : intl.formatMessage(
                                      msg.placeHolders.LicenceNumber,
                                  )
                        }
                    />
                </Column>
            </S.Row>
            <Controller
                key={`country_${item.driversArrayId}`}
                name={`driversArray.${index}.document.documentType.issuerName`}
                control={control}
                defaultValue={item?.document?.documentType?.issuerName}
                render={({ field, fieldState: { error } }) => (
                    <S.StyledFormField
                        key={`country_${item.driversArrayId}`}
                        isRequired
                        label={intl.formatMessage(msg.fields.issuingCountry)}
                        name={`driversArray.${index}.document.documentType.issuerName`}
                    >
                        <Select
                            data-test-id={`${selectIssuingCountry}-${index}`}
                            key={`country_${item.driversArrayId}`}
                            name={`driversArray.${index}.document.documentType.issuerName`}
                            value={
                                countries?.find(
                                    (option) => option === field.value,
                                ) ?? ''
                            }
                            onChange={(selected) => {
                                field.onChange(selected);
                                handleCountryChange(
                                    selected.target.value,
                                    index,
                                );
                            }}
                            placeholder={intl.formatMessage(
                                msg.placeHolders.issuingCountry,
                            )}
                            options={R.uniq(countries)}
                        />
                        <ValidationError
                            data-test-id={`${selectIssuingCountryErrorMessage}-${index}`}
                        >
                            {error?.message}
                        </ValidationError>
                    </S.StyledFormField>
                )}
            />
            {StateCountries.includes(
                item?.document?.documentType?.issuerName,
            ) && (
                <Controller
                    key={`state_${item.driversArrayId}`}
                    name={`driversArray.${index}.document.documentType.state`}
                    control={control}
                    defaultValue={item?.document?.documentType?.state}
                    render={({ field, fieldState: { error } }) => (
                        <S.StyledFormField
                            key={`state_${item.driversArrayId}`}
                            isRequired={
                                item?.document?.documentType?.issuerName ===
                                    StateCountries[UNITEDSTATES] ||
                                item?.document?.documentType?.issuerName ===
                                    StateCountries[AUSTRALIA]
                            }
                            label={intl.formatMessage(msg.fields.state)}
                            name={`driversArray.${index}.document.documentType.state`}
                        >
                            <Select
                                data-test-id={`${selectState}-${index}`}
                                key={`state_${item.driversArrayId}`}
                                name={`driversArray.${index}.document.documentType.state`}
                                value={
                                    statesArray?.find(
                                        (option) => option === field.value,
                                    ) ?? ''
                                }
                                onChange={(selected) => {
                                    field.onChange(selected);
                                    handleStateChange(
                                        selected.target.value,
                                        index,
                                    );
                                }}
                                placeholder={intl.formatMessage(
                                    msg.placeHolders.state,
                                )}
                                options={R.uniq(
                                    GetStatesArray(
                                        statesWithCountries,
                                        item?.document?.documentType
                                            ?.issuerName,
                                        item?.document?.documentType?.issuer,
                                    ),
                                )}
                            />
                            <ValidationError
                                data-test-id={`${selectStateErrorMessage}-${index}`}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.StyledFormField>
                    )}
                />
            )}
            <S.Row>
                <Controller
                    key={`issueDate_${item.driversArrayId}`}
                    name={`driversArray.${index}.document.validStart`}
                    control={control}
                    defaultValue={item?.document?.validStart}
                    render={({ field, fieldState: { error, invalid } }) => (
                        <S.AdjacentPairsWrapper>
                            <S.StyledFormField
                                key={`issueDate_${item.driversArrayId}`}
                                label={intl.formatMessage(msg.fields.issueDate)}
                                name={`driversArray.${index}.document.validStart`}
                            >
                                <DateTimePicker
                                    testId={`${dateIssueDate}-${index}`}
                                    key={`issueDate_${item.driversArrayId}`}
                                    name={`driversArray.${index}.document.validStart`}
                                    selected={prepareDate(field.value)}
                                    onChange={field.onChange}
                                    placeholderText={intl.formatMessage(
                                        msg.placeHolders.issueDate,
                                    )}
                                    isError={invalid}
                                ></DateTimePicker>
                            </S.StyledFormField>
                            <ValidationError
                                data-test-id={`${dateIssueDateErrorMessage}-${index}`}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.AdjacentPairsWrapper>
                    )}
                />
                <Controller
                    key={`expiryDate_${item.driversArrayId}`}
                    name={`driversArray.${index}.document.validEnd`}
                    control={control}
                    defaultValue={item?.document?.validEnd}
                    render={({ field, fieldState: { error, invalid } }) => (
                        <S.AdjacentPairsWrapper>
                            <S.StyledFormField
                                key={`expiryDate_${item.driversArrayId}`}
                                label={intl.formatMessage(
                                    msg.fields.expiryDate,
                                )}
                                name={`driversArray.${index}.document.validEnd`}
                            >
                                <DateTimePicker
                                    testId={`${dateExpiryDate}-${index}`}
                                    key={`expiryDate_${item.driversArrayId}`}
                                    name={`driversArray.${index}.document.validEnd`}
                                    selected={prepareDate(field.value)}
                                    onChange={field.onChange}
                                    placeholderText={intl.formatMessage(
                                        msg.placeHolders.expiryDate,
                                    )}
                                    isError={invalid}
                                ></DateTimePicker>
                            </S.StyledFormField>
                            <ValidationError
                                data-test-id={`${dateExpiryDateErrorMessage}-${index}`}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.AdjacentPairsWrapper>
                    )}
                />
            </S.Row>
            {isUKBrand && (
                <Controller
                    name={`driversArray.${index}.document.dateOfTest`}
                    control={control}
                    defaultValue={item?.document?.dateOfTest}
                    render={({ field, fieldState: { error, invalid } }) => (
                        <S.AdjacentPairsWrapper>
                            <S.StyledFormField
                                label={intl.formatMessage(
                                    msg.fields.dateOfTest,
                                )}
                                name="dateOfTest"
                            >
                                <DateTimePicker
                                    testId={`${dateOfTest}-${index}`}
                                    name="dateOfTest"
                                    selected={prepareDate(field.value)}
                                    onChange={field.onChange}
                                    placeholderText={intl.formatMessage(
                                        msg.placeHolders.dateOfTest,
                                    )}
                                    isError={invalid}
                                ></DateTimePicker>
                            </S.StyledFormField>
                            <ValidationError
                                data-test-id={`${dateOfTestErrorMessage}-${index}`}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.AdjacentPairsWrapper>
                    )}
                />
            )}
        </AdditionalDriverForm>
    );
};

export const AddAdditionalDriversForm: FC = () => {
    const {
        onSubmit,
        dialogVisible,
        dialogIndex,
        driversAreLoading,
        hideDialogAndRemoveComponent,
        setIndexAndDialogVisible,
        setDialogVisible,
        control,
        formState,
        trigger,
        fields,
        isAddingDisabled,
        addDriver,
        updateAdditionalDriverErrors,
        updateAdditionalDriversIsLoading,
        handleCountryChange,
        handleStateChange,
        statesWithCountries,
        register,
    } = useAddAdditionalDriversForm();

    const listErrors = Object.values(updateAdditionalDriverErrors);
    const errors = listErrors[0] ? Object.values(listErrors[0]) : [];
    const intl = useIntl();
    const addNewTitle = intl.formatMessage(
        msg.formHeaderItems.addNewAdditionalDriverTitle,
    );
    const feeMayApply = intl.formatMessage(msg.formHeaderItems.feeMayApply);

    if (driversAreLoading) {
        return <B2C.LoaderSpinner size="large" />;
    }

    return (
        <S.AddAdditionalDriversForm
            onSubmit={onSubmit}
            data-test-id={formAddAdditionalDrivers}
        >
            {fields.map((item, index: number) => {
                register(`driversArray.${index}.id`);
                register(`driversArray.${index}.document.id`);
                return (
                    <AdditionalDriver
                        key={`driversArray.${index}.id`}
                        item={item}
                        index={index}
                        dialogIndex={dialogIndex}
                        dialogVisible={dialogVisible}
                        errors={errors}
                        statesWithCountries={statesWithCountries}
                        control={control}
                        formState={formState}
                        trigger={trigger}
                        handleCountryChange={handleCountryChange}
                        handleStateChange={handleStateChange}
                        hideDialogAndRemoveComponent={
                            hideDialogAndRemoveComponent
                        }
                        setDialogVisible={setDialogVisible}
                        setIndexAndDialogVisible={setIndexAndDialogVisible}
                    />
                );
            })}
            <S.AdditionalDriversFormHeader>
                {addNewTitle}{' '}
                <S.SpanFeeMayApply>({feeMayApply})</S.SpanFeeMayApply>
                <S.StyledIconButton
                    testId={addAdditionalDriver}
                    cursor="pointer"
                    name={'addCircle'}
                    size="medium"
                    onClick={(event) => {
                        addDriver(fields.length);
                        event.preventDefault();
                    }}
                    isDisabled={isAddingDisabled}
                />
            </S.AdditionalDriversFormHeader>
            <S.ActionWrapper>
                <S.StyledPrimaryButton
                    data-test-id={submitButton}
                    isLoading={updateAdditionalDriversIsLoading}
                    type="submit"
                >
                    {intl.formatMessage(globalMsg.buttons.continue)}
                </S.StyledPrimaryButton>
            </S.ActionWrapper>

            {!fields.length && <S.WhiteSpace />}
            {errors &&
                errors.find((error) => error?.serverErrors?.length > 0) && (
                    <S.StyledNotificationBanner
                        message={
                            <NotificationBannerMessage
                                header={
                                    <strong>
                                        {intl.formatMessage(
                                            msg.error.pleaseContactUs,
                                            msg.error.someThingWrong,
                                        )}
                                    </strong>
                                }
                                content={
                                    <>
                                        {errors.map(
                                            (error) =>
                                                error?.serverErrors &&
                                                error?.serverErrors?.length >
                                                    0 &&
                                                error?.serverErrors?.map(
                                                    (
                                                        serverError: any,
                                                        index: any,
                                                    ) => {
                                                        return (
                                                            <S.StyledMessageContent
                                                                key={index}
                                                            >
                                                                {GenerateErrorMessage(intl, serverError)}
                                                            </S.StyledMessageContent>
                                                        );
                                                    },
                                                ),
                                        )}
                                        <>
                                            {intl.formatMessage(
                                                msg.error.pleaseContactUs,
                                            )}
                                        </>
                                    </>
                                }
                            />
                        }
                        type={'error'}
                        isClosable={false}
                        notificationIconSize="medium1"
                        hasBorder
                        isNotificationIconFilled
                    />
                )}
        </S.AddAdditionalDriversForm>
    );
};

export const AdditionalDriverForm = styled.div``;

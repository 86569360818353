import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
    RESET_APP_ACTION,
} from 'src/utils/redux';
import { RetrieveCountriesState, Countries } from './types';
import { favoriteCountries } from 'src/constants';
import { convertObjectToArrayByKey } from 'src/utils';

import { ServerError } from '../types';

const wrapWithNamespace = wrapWithModule('modules/retrieveCountries');
export const retrieveCountriesAction = createRoutine(
    wrapWithNamespace('retrieve'),
);

const setCountryOptionsAction = wrapWithNamespace('setCountryOptions');

const defaultCountries: Countries = [
    { code: '', name: '', id: { organizationId: '', pointId: '' } },
];
const updateInitialState: RetrieveCountriesState =
    genApiSchemaState<RetrieveCountriesState>({
        data: defaultCountries,
        countryOptions: [],
    });

const retrieveCountriesReducer = (
    state = updateInitialState,
    { type, payload }: Action<Countries>,
): RetrieveCountriesState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

export const setCountryOptions = (countries: Countries) => {
    return { type: setCountryOptionsAction, payload: countries };
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: RetrieveCountriesState, payload: Countries) => ({
    ...state,
    error: undefined,
    loading: false,
    data: { ...state.data, ...payload },
});

const handleError = (_: RetrieveCountriesState, payload: ServerError) => ({
    ...updateInitialState,
    error: payload,
});

const handleSetCountryOptionsAction = (
    state: RetrieveCountriesState,
    payload: any,
) => {
    const newCountryOptions = [
        ...favoriteCountries,
        ...convertObjectToArrayByKey(payload, 'name').sort(),
    ];
    return {
        ...state,
        countryOptions: newCountryOptions,
    };
};

const HANDLERS = {
    [retrieveCountriesAction.TRIGGER]: handleTrigger,
    [retrieveCountriesAction.SUCCESS]: handleSuccess,
    [retrieveCountriesAction.FAILURE]: handleError,
    [setCountryOptionsAction]: handleSetCountryOptionsAction,
    [RESET_APP_ACTION]: () => updateInitialState,
};

export default retrieveCountriesReducer;

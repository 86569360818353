import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as R from 'ramda';
import { useIntl } from 'react-intl';

import { GooglePlacesAutoComplete } from 'src/components/shared/GooglePlacesAutoComplete';
import { Input, PhoneInput, Select, Checkbox } from 'src/components/form';
import { Row } from 'src/components/shared/Row';
import { Column } from 'src/components/shared/Column';
import { sciBrandNameSelector, sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { useConfiguration } from 'src/hooks/useConfiguration';
import { languageOptions } from 'src/constants';
import { CaBrandNames } from 'src/enums';

import { useUpdateCustomerInfoForm } from './updateCustomerInfo.hooks';
import msg from './updateCustomerInfoMessages';
import * as S from './updateCustomerInfo.form.style';
import ShuttleService from './shuttleService/shuttleService.component';
import LoyaltyMembershipForm from './loyaltyMembership/loyaltyMembership.form';
import { useGetShuttleService } from './shuttleService/shuttleService.hook';
import { LoyaltyCodes } from './loyaltyMembership/loyaltyMembership.constants';
import { LoyaltyProgram } from './loyaltyMembership/loyaltyMembership.types';

const MODULE_PREFIX = 'updateCustomerInfo';
export const formUpdateCustomerInfo = `${MODULE_PREFIX}-form`;
export const inputLastName = `${MODULE_PREFIX}-input-lastName`;
export const inputFirstName = `${MODULE_PREFIX}-input-firstName`;
export const inputEmail = `${MODULE_PREFIX}-input-email`;
export const inputMobile = `${MODULE_PREFIX}-input-mobile`;
export const inputPhone = `${MODULE_PREFIX}-input-phone`;
export const inputAddressLine1 = `${MODULE_PREFIX}-input-addressLine1`;
export const inputAddressLine2 = `${MODULE_PREFIX}-input-addressLine2`;
export const inputCity = `${MODULE_PREFIX}-input-city`;
export const inputPostCode = `${MODULE_PREFIX}-input-postCode`;
export const selectCountry = `${MODULE_PREFIX}-select-country`;
export const inputState = `${MODULE_PREFIX}-input-state`;
export const selectAdultsNum = `${MODULE_PREFIX}-select-adultsNum`;
export const selectChildrenNum = `${MODULE_PREFIX}-select-childrenNum`;
export const selectPreferredLanguage = `${MODULE_PREFIX}-select-preferredLanguage`;
export const checkBoxIsDriver = `${MODULE_PREFIX}-checkBox-isDriver`;
export const submitButton = `${MODULE_PREFIX}-submit-button`;

export const UpdateCustomerInfoForm: FC = () => {
    const {
        customerInfo,
        control,
        formState,
        trigger,
        onSubmit,
        onGooglePlacesAutoCompleteSelect,
        setPhoneOrMobileObject,
        onAdultsItemSelected,
        countries,
        isFormLoading,
        isDriverDisabled,
        emailError,
        onPassengerChange,
        onCountryChange,
        onLoyaltyMembershipChange,
        onPreferredLanguageChange,
        country,
        isShuttleSelected,
        loyaltyPrograms,
    } = useUpdateCustomerInfoForm();
    const {
        email,
        firstName,
        lastName,
        mobile,
        phone,
        address,
        adultsNum,
        childrenNum,
        isDriver,
        preferredLanguage,
    } = customerInfo;

    if (address.country === '' && country) {
        address.country = country.name;
    }

    const intl = useIntl();
    const configuration = useConfiguration();
    const [searchParams, setSearchParams] = useSearchParams();
    const reduxLanguage  = useSelector(sciLanguageSelector);
    const brandName = useSelector(sciBrandNameSelector);
    const isCa = brandName === CaBrandNames.canadream;
    const [lang, setLang] = useState(reduxLanguage);
    const isErr = !!Object.keys(formState?.errors).length;

    useEffect(() => {
        if (isErr && lang !== reduxLanguage) {
            trigger();
            setLang(reduxLanguage);
        }
    }, [reduxLanguage, lang, trigger, isErr]);

    const preferredLangOpt = languageOptions.find(
        (opt) =>
            opt?.id?.toLowerCase() === preferredLanguage?.locale?.toLowerCase(),
    ) || languageOptions[0];

    if (isCa && !reduxLanguage && preferredLanguage?.locale) {
        searchParams.set('lng', preferredLangOpt?.label?.toLowerCase());
        setSearchParams(searchParams);
    }

    const { shuttlePoints, isLoading: areShuttlePointsLoading } =
        useGetShuttleService();

    const generateErrorMessage = useCallback(() => {
        if (emailError === 'mismatch') {
            return intl.formatMessage(msg.fields.emailMisMatch);
        } else if (emailError === 'exists') {
            return intl.formatMessage(msg.fields.emailExists);
        }
        return null;
    }, [intl, emailError]);

    const qffLoyaltyProgram = loyaltyPrograms?.find(
        (loyaltyProgram) =>
            loyaltyProgram?.loyaltyProgram?.code === LoyaltyCodes.QFF,
    );

    return (
        <S.CustomerInfoForm
            data-test-id={formUpdateCustomerInfo}
            onSubmit={onSubmit}
        >
            <Row>
                <Input
                    testId={inputEmail}
                    name="email"
                    control={control}
                    defaultValue={email}
                    label={intl.formatMessage(msg.fields.email)}
                    placeholder={intl.formatMessage(msg.placeHolders.email)}
                    disabled={
                        !!customerInfo.email &&
                        emailError !== 'mismatch' &&
                        emailError !== 'exists'
                    }
                    errorMessage={generateErrorMessage()}
                />
            </Row>
            <Row>
                <Column size={6}>
                    <Input
                        testId={inputFirstName}
                        name="firstName"
                        control={control}
                        defaultValue={firstName}
                        label={intl.formatMessage(msg.fields.firstName)}
                        placeholder={intl.formatMessage(
                            msg.placeHolders.firstName,
                        )}
                        disabled={emailError !== 'mismatch'}
                    />
                </Column>
                <Column size={6}>
                    <Input
                        testId={inputLastName}
                        name="lastName"
                        control={control}
                        defaultValue={lastName}
                        label={intl.formatMessage(msg.fields.lastName)}
                        placeholder={intl.formatMessage(
                            msg.placeHolders.lastName,
                        )}
                        disabled={emailError !== 'mismatch'}
                    />
                </Column>
            </Row>
            <Row>
                <Column size={6} sm={12}>
                    <PhoneInput
                        testId={inputMobile}
                        name="mobile"
                        control={control}
                        defaultValue={mobile}
                        label={intl.formatMessage(msg.fields.mobile)}
                        placeholder={intl.formatMessage(
                            msg.placeHolders.mobile,
                        )}
                        onChange={(value) =>
                            setPhoneOrMobileObject('mobile', value)
                        }
                    />
                </Column>
                <Column size={6} sm={12}>
                    <PhoneInput
                        testId={inputPhone}
                        name="phone"
                        isRequired={false}
                        control={control}
                        defaultValue={phone}
                        label={intl.formatMessage(msg.fields.phone)}
                        placeholder={intl.formatMessage(msg.placeHolders.phone)}
                        onChange={(value) =>
                            setPhoneOrMobileObject('phone', value)
                        }
                    />
                </Column>
            </Row>

            <S.StyledFormField
                label={intl.formatMessage(msg.fields.homeAddressLookup)}
                name="homeAddressLookup"
            >
                <GooglePlacesAutoComplete
                    onSelect={onGooglePlacesAutoCompleteSelect}
                />
            </S.StyledFormField>

            <Input
                testId={inputAddressLine1}
                name="address.line1"
                control={control}
                defaultValue={address.line1}
                label={intl.formatMessage(msg.fields.addressLine1)}
                placeholder={intl.formatMessage(msg.placeHolders.addressLine1)}
                isRequired={true}
                onlyAscii
            />

            <Input
                testId={inputAddressLine2}
                name="address.line2"
                control={control}
                defaultValue={address.line2}
                label={null}
                placeholder={intl.formatMessage(msg.placeHolders.addressLine2)}
                isRequired={false}
                onlyAscii
            />

            <Row>
                <Column size={6}>
                    <Input
                        testId={inputCity}
                        name="address.city"
                        control={control}
                        defaultValue={address.city}
                        label={intl.formatMessage(msg.fields.city)}
                        placeholder={intl.formatMessage(msg.placeHolders.city)}
                        onlyAscii
                    />
                </Column>
                <Column size={6}>
                    <Input
                        testId={inputPostCode}
                        name="address.postCode"
                        control={control}
                        defaultValue={address.postCode}
                        label={intl.formatMessage(msg.fields.postCode)}
                        placeholder={intl.formatMessage(
                            msg.placeHolders.postCode,
                        )}
                        onlyAscii
                    />
                </Column>
            </Row>
            <Row>
                <Column size={6}>
                    <Select
                        testId={selectCountry}
                        name="address.country"
                        control={control}
                        defaultValue={address.country}
                        label={intl.formatMessage(msg.fields.country)}
                        placeholder={intl.formatMessage(
                            msg.placeHolders.country,
                        )}
                        options={R.uniq(countries)}
                        valueController={(value) =>
                            countries?.find((option) => option === value)
                        }
                        onChange={(selected) => {
                            onCountryChange(selected?.target?.value);
                        }}
                    />
                </Column>
                <Column size={6}>
                    <Input
                        testId={inputState}
                        name="address.state"
                        control={control}
                        defaultValue={address.state}
                        label={intl.formatMessage(msg.fields.state)}
                        placeholder={intl.formatMessage(msg.placeHolders.state)}
                        onlyAscii
                    />
                </Column>
            </Row>
            {isCa && (
                <Row>
                    <Column size={6}>
                        <Select
                            testId={selectPreferredLanguage}
                            name="preferredLanguage.name"
                            control={control}
                            defaultValue={preferredLangOpt.value}
                            label={intl.formatMessage(
                                msg.fields.preferredLanguage,
                            )}
                            options={languageOptions.map((opt) => opt.value)}
                            onChange={(selected) => {
                                onPreferredLanguageChange(
                                    selected?.target?.value,
                                );
                            }}
                        />
                    </Column>
                </Row>
            )}
            <S.PassengersHeader>
                {intl.formatMessage(msg.formHeaderItems.passengersHeader)}
            </S.PassengersHeader>
            <Row>
                <Column size={6}>
                    <Select
                        testId={selectAdultsNum}
                        name="adultsNum"
                        control={control}
                        defaultValue={adultsNum}
                        onChange={(selected) => {
                            const value = parseInt(selected.target.value, 0);
                            onAdultsItemSelected(value);
                            onPassengerChange(
                                'adultsNum',
                                value,
                                'childrenNum',
                            );
                        }}
                        label={intl.formatMessage(msg.fields.adults)}
                        options={R.range(1, customerInfo.maxAdults + 1)}
                    />
                </Column>
                <Column size={6}>
                    <Select
                        testId={selectChildrenNum}
                        name="childrenNum"
                        control={control}
                        defaultValue={childrenNum}
                        label={intl.formatMessage(msg.fields.children)}
                        options={R.range(0, customerInfo.maxChildren + 1)}
                        onChange={(selected) => {
                            const value = parseInt(selected.target.value, 0);
                            onPassengerChange(
                                'childrenNum',
                                value,
                                'adultsNum',
                            );
                        }}
                    />
                </Column>
            </Row>
            <S.Row>
                <Checkbox
                    testId={checkBoxIsDriver}
                    name="isDriver"
                    control={control}
                    defaultValue={isDriver}
                    disabled={isDriverDisabled}
                    checked={isDriverDisabled}
                    label={intl.formatMessage(msg.fields.driver)}
                />
            </S.Row>
            {configuration.functionalities.shuttleService.active && (
                <S.Row>
                    <Column size={12}>
                        <ShuttleService
                            isShuttleSelected={isShuttleSelected}
                            shuttlePoints={shuttlePoints}
                            control={control}
                        />
                    </Column>
                </S.Row>
            )}
            {configuration.functionalities.loyaltyPrograms.active &&
                qffLoyaltyProgram && (
                    <S.LoyaltyMembershipRow>
                        <Column size={12}>
                            <LoyaltyMembershipForm
                                control={control}
                                loyaltyProgram={
                                    qffLoyaltyProgram?.loyaltyProgram as LoyaltyProgram
                                }
                                points={
                                    (qffLoyaltyProgram?.points?.base ?? 0) +
                                    (qffLoyaltyProgram?.points?.bonus ?? 0)
                                }
                                lastName={lastName}
                                onLoyaltyMembershipChange={
                                    onLoyaltyMembershipChange
                                }
                            />
                        </Column>
                    </S.LoyaltyMembershipRow>
                )}
            <S.ActionWrapper>
                <S.StyledPrimaryButton
                    data-test-id={submitButton}
                    isLoading={isFormLoading || areShuttlePointsLoading}
                    type="submit"
                >
                    {intl.formatMessage(msg.buttons.continue)}
                </S.StyledPrimaryButton>
            </S.ActionWrapper>
        </S.CustomerInfoForm>
    );
};
